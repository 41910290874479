<template>
  <v-app>
    <body>
      <v-container>
        <v-container class="white-bg mt-48px ct-mw">
          <div class="text-xs-center">
            <v-row justify="center">
              <h4 class="navy-text">PATHWAY & WORKSHOP</h4>
            </v-row>
            <v-row justify="center">
              <div class="subtitle">ชุดวิชา & เวิร์กชอป</div>
            </v-row>
            <v-divider></v-divider>
            <v-content>
              <div>
                <div
                  v-if="hasProductActive"
                  class="subtitle text-xs-left"
                >
                  <strong>Upcoming Promotions</strong>
                </div>
                <br/>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4" mb-3
                      v-for="workshop in productsActive"
                      :key="workshop.id">
                        <v-card class="text-xs-left" height="100%">
                          <v-img
                            class="white--text"
                            height="200px"
                            aspect-ratio="2.75"
                            :src="workshop.bg"
                          >
                          <!-- :src="require('@/assets/image/cover-03.png')" -->
                            <v-container fill-height fluid>
                              <v-row fill-height>
                                <v-col xs="12" >
                                  <span class="title">{{ workshop.name }}</span>
                                  <div class="underline"></div>
                                  <div class="ep">{{ workshop.ep }}</div>
                                  <div class="tag new subtitle">{{ workshop.tag }}</div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                          <v-card-title>
                            <div class="grey--text des-text">
                              {{ workshop.description }}
                            </div>
                            <div class="subtitle navy-text">
                              <strong>{{ workshop.price }} บาท</strong>
                            </div>
                          </v-card-title>
                          <v-card-actions>
                            <v-col class="text-xs-right ltsp">
                              <v-row justify="end">
                                <v-btn class="ma-1" :to="`${workshop.link1}`">
                                {{ workshop.button1 }}</v-btn>
                                <v-btn :to="workshop.link2" color="error" class="white-text ma-1">
                                {{ workshop.button2 }}</v-btn>
                              </v-row>
                            </v-col>
                          </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div class="">
                  <div
                    class="subtitle text-xs-left"
                  >
                    <strong>Past Promotions</strong>
                  </div>
                  <br/>
                  <v-row>
                    <!-- <v-col cols="12" sm="6" md="6" lg="4" xl="4" mb-3>
                        <v-card class="text-xs-left">
                          <v-img
                            class="white--text"
                            height="200px"
                            aspect-ratio="2.75"
                            :src="require('../../assets/image/cover-04.jpg')"
                          >
                            <v-container fill-height fluid>
                              <v-row fill-height>
                                <v-col cols="12" >
                                  <span class="title">
                                    [Pathway to Achieve]: Achieve x Wisesight
                                  </span>
                                  <div class="underline"></div>
                                  <div class="tag subtitle">Sold Out</div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                          <v-card-title>
                            <div> -->
                              <!-- <span>Achieve x Wisesight
                                <br/></span><br> -->
                              <!-- <span class="grey--text">
                                27 ส.ค. 2562
                                เวลา 12.30 - 16.00 น.<br/>
                                Wisesight ตึก Suntowers ชั้น 33 โซน B เดินทาง MRT (ลงสถานีจตุจักร)
                                </span><br/> -->
                              <!-- <div class="subtitle navy-text">
                                <strong>ฟรี</strong>
                              </div>
                            </div>
                          </v-card-title>
                        <v-col class="white-text ltsp">
                          <v-row justify="end" >
                            <v-btn class="ma-2" to="/cv/1">
                            DETAILS</v-btn> -->
                            <!-- <a href="http://nav.cx/g05ap0M">
                            <v-btn dark class="white-text">
                            REGISTER</v-btn></a> -->
                          <!-- </v-row>
                        </v-col>
                      </v-card>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="6" lg="4" xl="4" mb-3
                      v-for="workshop in productsInActive"
                      :key="workshop.id">
                        <v-card class="text-xs-left">
                          <v-img
                            class="white--text"
                            height="200px"
                            aspect-ratio="2.75"
                            :src="workshop.bg"
                          >
                          <!-- :src="require('@/assets/image/cover-03.png')" -->
                            <v-container fill-height fluid>
                              <v-row fill-height>
                                <v-col cols="12" >
                                  <span class="title">{{ workshop.name }}</span>
                                  <div class="underline"></div>
                                  <!-- <div class="ep">{{ workshop.ep }}</div> -->
                                  <div class="tag subtitle">{{ workshop.tag }}</div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-img>
                          <v-card-title>
                            <div>
                              <!-- <span>{{ workshop.instructor }}
                                <br/>{{ workshop.department }}</span><br>
                              <span class="grey--text">{{ workshop.date }}
                                เวลา {{ workshop.time }} น.<br/>
                              {{ workshop.location }}</span><br/> -->
                              <div class="subtitle navy-text">
                                <strong>{{ workshop.price }} บาท</strong>
                              </div>
                            </div>
                          </v-card-title>
                        <v-col class="white-text ltsp">
                          <v-row justify="end">
                            <v-btn class="ma-2" :to="`${workshop.link1}`">
                            {{ workshop.button1 }}</v-btn>
                          </v-row>
                        </v-col>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
            </v-content>
          </div>
        </v-container>
      </v-container>
    </body>
  </v-app>
</template>
<script>
import products from '../../library/products';

export default {
  name: 'workshops',
  data() {
    return {
      card_text: '',
      productsActive: [],
      productsInActive: [],
      products: [],
      hasProductActive: false,
      hasProductInActive: false,
    };
  },
  async mounted() {
    await this.fetchWorkshops();
  },
  methods: {
    async fetchWorkshops() {
      try {
        this.products = await products.getAllProduct();
        // this.workshops.sort((a, b) => b.saleEnd.seconds - a.saleEnd.seconds);
        this.products.map((item) => {
          item.bg = require(`../../assets/image/${item.bg}`);
          item.price = item.prices.sale;
          if (item.active) {
            this.productsActive.push(item);
          } else {
            this.productsInActive.push(item);
          }
          return item;
        });
        this.hasProductActive = this.productsActive.length > 0;
        this.hasProductInActive = this.productsInActive.length > 0;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.des-text
  font-size: 0.87rem
.subtitle
  font-family: 'Montserrat', Prompt
.mt-48px
  margin-top: 48px !important
  @media screen and (max-width: 770px)
    margin-top: 24px !important
.mt-32px
  margin-top: 32px !important
  @media screen and (max-width: 770px)
    margin-top: 0px !important
.ct-mw
  max-width: 1100px
  margin: auto
.ltsp
  font-weight: 700
  letter-spacing: 2px
.white-text
  color: white !important
.navy-text
  color: #001544 !important
.title
  font-size: 1.4rem !important
  font-weight: 600
  line-height: 2.0rem !important
  font-family: 'Prompt' !important
  @media screen and (max-width: 770px)
    font-size: 1.4rem !important
    line-height: 1.5rem !important
.ep
  font-size: 1.2rem
  font-weight: 600
  font-family: 'Prompt' !important
  color: #ffc012
  text-shadow: 2px 2px #105982
  margin-top: 16px
  @media screen and (max-width: 770px)
    font-size: 1.8rem !important
    line-height: 1.5rem !important
    // padding-top: 40px !important
.underline
  height: 2px
  max-width: 150px
  background-color: #ffc012
  margin-top: 16px
  margin-bottom: 16px
.tag
  background-color: rgba(250, 250, 250, 0.3)
  border-radius: 8px 0px 0px 8px
  padding: 6px
  font-size: 14px !important
  font-weight: 600 !important
  letter-spacing: 1px
  color: #f8f8f9
  // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.19)
  position: absolute
  right: 0
  top: 0
  @media screen and (max-width: 770px)
    top: unset
    bottom: 0
.tag.new
  background-image: linear-gradient(to right, #B4A40C, #5A5206)
</style>
